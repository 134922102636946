import React from "react";
import FHero from "../components/FHero";
import { FaSms } from "react-icons/fa";
import sms_p from "../images/smshmore.png";
import sms_h from "../images/sms_h.png";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function SmsMessage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "SMS Messaging",
    body1: "Make sure your message reaches everyone",
    body2:
      "Parish are able to send bulk sms to members on Parishioner App Admin Portal",
    itIcon: FaSms,
    hcolor: "ppink",
    heropic: sms_h,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: sms_p,
    textP0: "Keep Parishioners Informed on all Important Information",
    textP1Title: "SMS",
    textP1: "SMS Messaging can be targeted at groups in the Church.",
    textP2Title: "Inclusive",
    textP2:
      "Parishioners who do not have smartphone can also stay connected with the Parish by receiving important information through SMS",
    textP3: "",
  };

  return (
    <div>
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </div>
  );
}

export default SmsMessage;
