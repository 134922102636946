import { filter } from "@chakra-ui/react";
import {
  LOADING_UI,
  CLEAR_ERRORS,
  SET_LOCATION,
  SET_SUBSCRIPTION_TYPE,
  RESET_SUBSCRIPTION_TYPE,
  END_LOADING_UI,
  SET_ERRORS,
} from "../types";

const initialState = {
  loading: "false",
  isLocation: "",
  isSubscriptionType: [
    { name: "Free", id: 1 },
    { name: "Standard", id: 2 },
  ],
  error: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: "true",
      };
    case END_LOADING_UI:
      return {
        ...state,
        loading: "false",
      };
    case SET_LOCATION:
      return {
        ...state,
        isLocation: action.payload,
      };
    case SET_SUBSCRIPTION_TYPE:
      let subscription = state.isSubscriptionType.findIndex(
        (type) => type.name !== action.payload
      );
      state.isSubscriptionType.splice(subscription, 1);
      return {
        ...state,
      };
    case RESET_SUBSCRIPTION_TYPE:
      return {
        ...state,
        isSubscriptionType: [...action.payload],
      };
    case SET_ERRORS:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: "",
        loading: "false",
      };
    default:
      return state;
  }
}
