import React from "react";
import FHero from "../components/FHero";
import { MdEventNote } from "react-icons/md";
import evt_p from "../images/eventhmore2.png";
import eventHero from "../images/eventHero.png";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function Event() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Event",
    body1: "Keep Note of what is to take place",
    body2:
      "With Event, you will keep track of all activities that is to take place in your parish.",
    itIcon: MdEventNote,
    hcolor: "ppurple",
    heropic: eventHero,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: evt_p,
    textP0: "Making It Easy for Churches to Engage with their community",
    textP1Title: "Get notified",
    textP1:
      "You get notified when your Church sets a new Event for an upcoming program.",
    textP2Title: "Reminders",
    textP2:
      "Set Reminders on Events so you can receive notification when an Event is due and also share Events to other platforms",
    textP3: "",
  };

  return (
    <div>
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </div>
  );
}

export default Event;
