import {
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Stack,
  Text,
  HStack,
} from "@chakra-ui/react";
import * as React from "react";
import { FaLinkedin, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setLocation } from "../redux/actions/uiAction";
import Logo from "../util/logo";

export const Footer_ = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const NavButtonClick = (e, reference) => {
    e.preventDefault();
    dispatch(setLocation(reference));
    navigate(reference, { replace: true });
  };
  return (
    <Box
      bg="brand.800"
      color="on-accent"
      w="full"
      px={["12", "12", "16", "16"]}
    >
      <Box py={["12", "12", "16", "16"]} w="full">
        <Stack spacing={["4", "4", "5", "5"]}>
          <Stack justify="space-between" direction="row" align="center">
            <Logo h="8" iconColor={"brand.50"} color={"blue"} />

            <ButtonGroup variant="ghost-on-accent">
              <IconButton
                as="a"
                href="https://www.youtube.com/channel/UCEgLcWDj6XgnxCS_VFPSV5A"
                aria-label="YouTube"
                color={"brand.50"}
                icon={<FaYoutube fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="https://instagram.com/parishioner_hq?igshid=YmMyMTA2M2Y="
                aria-label="Instagram"
                color={"brand.50"}
                icon={<FaInstagram fontSize="1.25rem" />}
              />
              <IconButton
                as="a"
                href="https://twitter.com/Parishioner_HQ?s=20&t=9Qo5ghzxBvtS5O-tSkK--g"
                aria-label="Twitter"
                color={"brand.50"}
                icon={<FaTwitter fontSize="1.25rem" />}
              />
            </ButtonGroup>
          </Stack>
          <HStack justify="start" minW="36">
            {/* <Text fontSize="sm" fontWeight="semibold" color="subtle">
              Legal
            </Text> */}
            <Text fontSize="sm" color="brand.50">
              &copy; {new Date().getFullYear()} Parishioner
            </Text>
            <Button
              size={"sm"}
              variant="link"
              onClick={(e) => NavButtonClick(e, "/privacy-policy")}
            >
              Privacy
            </Button>
            <Button
              size={"sm"}
              variant="link"
              onClick={(e) => NavButtonClick(e, "/terms-of-service")}
            >
              Terms
            </Button>
            {/* <Button size={"sm"} variant="link">
                License
              </Button> */}
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
};
