import React from "react";
import FHero from "../components/FHero";
import { FaBullhorn } from "react-icons/fa";
import anc_p from "../images/announcementhmore.png";
import anc_h from "../images/anc_h.png";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function Announcement() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Announcement",
    body1: "Receive Sunday Weekly Announcement",
    body2:
      "Stay organized and informed with Sundays Announcement on Parishioner App",
    itIcon: FaBullhorn,
    hcolor: "paleblue",
    heropic: anc_h,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: anc_p,
    textP0: "Making It Easy for Churches to Engage with their community",
    textP1Title: "Sunday After Mass Announcement",
    textP1:
      "Announcement in Parishioner App is modelled upon the popular Sunday After Mass Announcement. You receive notification when your Parish posts Announcement.",
    textP2Title: "Bookmark Announcement",
    textP2:
      " Bookmark Announcement and also share Announcement to friends on other platforms",
    textP3: "",
  };

  return (
    <div>
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </div>
  );
}

export default Announcement;
