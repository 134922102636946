import React from "react";
import FHero from "../components/FHero";
import { MdMessage } from "react-icons/md";
import mes_p from "../images/mes_p.png";
import mes_h from "../images/megHero.png";
import { Box } from "@chakra-ui/react";
import { Footer_ } from "../components/Footer_";
import { useEffect } from "react";
import { FMore_ } from "../components/fmoreComp/FMore_";

function Message() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Messages",
    body1: "Receive Messages from Your Parish",
    body2:
      "Message is where parishioners get push notifications from Parishioner App about happenings in their Church",
    itIcon: MdMessage,
    hcolor: "#FDC613",
    heropic: mes_h,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: mes_p,
    textP0: "Keep Parishioners Informed on all Important Information",
    textP1Title: "Simple Design",
    textP1:
      "Your Message UI is better Organized to make access easy. Share Messages to other platforms. Bookmark important messages for future reference",
    textP2Title: "Message Notification",
    textP2:
      "Parishioner App will notify you with all messages from your Parish to keep you in the known of all things important",
    textP3: "",
  };

  return (
    <Box display="flex" flexDirection="column">
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </Box>
  );
}

export default Message;
