import { BsCalendar4Event } from "react-icons/bs";
import { FaSms, FaChurch, FaBookReader } from "react-icons/fa";
import { GiNewspaper } from "react-icons/gi";
import { BiMessageRoundedDots } from "react-icons/bi";
import { ImBullhorn } from "react-icons/im";

export const features = [
  {
    name: "Message (InApp)",
    description:
      "Message is where parishioners get push messages from Parishioner App about what is happening in their Parish...",
    icon: BiMessageRoundedDots,
    reff: "/message",
  },
  {
    name: "Word Daily",
    description:
      "Your word is a lamp for my feet, a light on my path Psalm 119: 103 Daily Mass Readings available for parishioners to read",
    icon: FaBookReader,
    reff: "/wordDaily",
  },
  {
    name: "My Parish",
    description:
      " All The Important information about your Parish in one place. Find more about your parish Mass Time, Confession Time",
    icon: FaChurch,
    reff: "/myparish",
  },
  {
    name: "Parish Event",
    description:
      "With Event, you will keep track of all activities that are to take place in your parish.",
    icon: BsCalendar4Event,
    reff: "/event",
  },
  {
    name: "Announcement",
    description:
      "Stay organized and informed with Sundays Announcement on Parishioner App",
    icon: ImBullhorn,
    reff: "/announcement",
  },
  {
    name: "SMS Messaging",
    description:
      "Parishes are able to send bulk sms to church members on the Parishioner portal  platform",
    icon: FaSms,
    reff: "/smsMessage",
  },
  {
    name: "Parish Bulletin",
    description:
      "Stay close to what is happening in your Church. Access your Parish weekly bulletin on Parishioner App",
    icon: GiNewspaper,
    reff: "/bulletin",
  },
];
