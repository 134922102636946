import {
  SET_LOCATION,
  SET_SUBSCRIPTION_TYPE,
  RESET_SUBSCRIPTION_TYPE,
} from "../types";
export const setLocation = (data) => (dispatch) => {
  dispatch({ type: SET_LOCATION, payload: data });
};
export const setSubscriptionType = (data) => (dispatch) => {
  dispatch({ type: SET_SUBSCRIPTION_TYPE, payload: data });
};
export const resetSubscriptionType = () => (dispatch) => {
  const data = [
    { name: "Free", id: 1 },
    { name: "Standard", id: 2 },
  ];
  dispatch({ type: RESET_SUBSCRIPTION_TYPE, payload: data });
};
