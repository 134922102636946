import { extendTheme, theme as base } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: ["30em", "48em", "62em", "80em"],
  fonts: {
    heading: '"Avenir Next", sans-serif',
    body: "system-ui, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem",
  },
  colors: {
    darkblue: "#0f224d",
    oorange: "#39C2FF",
    orangeshade: "#EBE7E4",
    yyellow: "#FDC613",
    paleblue: "#7D75EA",
    ggreen: "#36A10F",
    wwhite: "#F2F9FF",
    rred: "#FF7E45",
    ppurple: "#B169CF",
    lightblue: "#7D75EA",
    ppink: "#FF3C77",
    ggray: "#807A81",
    whiteshade: "#F4F4F4",
    grayshade: "#CDCDCD",
    darkblue_light: "#434347",
    btnblue: "#00B0FF",
    brand: {
      10: "#F2FFFA", //light green
      20: "#FBFDFF",
      30: "#E5F1FC", //home card bg
      50: "#F2F9FF", //hover
      100: "#DEEEFD",
      200: "#FA9292", //red
      300: "#055C18", //green
      400: "#C8E2FA", //select
      500: "#0987A0",
      600: "#0987A0",
      700: "#3C85A6",
      800: "#0E455E",
      900: "#053347",
    },
  },

  components: {
    Input: {
      variants: {
        outline: {
          field: {
            border: "1px solid",
            _focus: {
              borderColor: "brand.800",
              boxShadow: "none",
            },
          },
        },
      },
      sizes: {
        lg: {
          field: {
            borderRadius: "none",
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            border: "1px solid",
            _focus: {
              borderColor: "brand.800",
              boxShadow: "none",
            },
          },
        },
      },
      sizes: {
        lg: {
          field: {
            borderRadius: "none",
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "2px",
      },
      variants: {
        solid: {
          color: "brand.800",
          _focus: {
            borderColor: "brand.800",
            bg: "brand.700",
            boxShadow: "none",
            color: "white",
          },
          _hover: {
            borderColor: "brand.800",
            bg: "brand.600",
            boxShadow: "none",
            color: "white",
          },
        },
        ghost: {
          color: "brand.800",
          _focus: {
            borderColor: "brand.800",
            bg: "brand.100",
            boxShadow: "none",
            color: "brand.800",
          },
          _hover: {
            borderColor: "brand.800",
            bg: "brand.100",
            boxShadow: "none",
            color: "brand.800",
          },
        },
        outline: {
          borderColor: "brand.700",
          color: "brand.800",
          _focus: {
            borderColor: "brand.700",
            boxShadow: "none",
            color: "brand.700",
          },
          _hover: {
            borderColor: "brand.700",
            bg: "brand.700",
            boxShadow: "none",
            color: "white",
          },
        },
        link: {
          _focus: {
            borderColor: "none",
            boxShadow: "none",
            color: "brand.700",
          },
          _hover: {
            color: "brand.50",
          },
        },
      },
    },
  },
});
export default theme;
