import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
  CSS,
  ChakraProviderReset,
  Box,
} from "@chakra-ui/react";
import theme from "./theme";
import Home from "./pages/home";
import Navbar from "./components/Navbar";

//pages
import Overview from "./pages/overview";
import Pricing from "./pages/pricing";
import Message from "./pages/message";
import MyParish from "./pages/myParish";
import WordDaily from "./pages/wordDaily";
import Event from "./pages/event";
import Announcement from "./pages/announcement";
import SmsMessage from "./pages/smsMessage";
import Bulletin from "./pages/bulletin";
import GetStarted from "./pages/getStarted";

//Redux
import store from "./redux/store";
import { Provider } from "react-redux";
import { PageNotFound } from "./pages/pageNotFound";
import Privacy from "./pages/privacy";
import TermsOfService from "./pages/termsOfService";

function App() {
  return (
    <ChakraProvider theme={theme}>
      {/* <Provider store={store}> */}
      <Router>
        <Box display="flex" flexDirection="column" minHeight="100%">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/overview" element={<Overview />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/message" element={<Message />} />
            <Route exact path="/myParish" element={<MyParish />} />
            <Route exact path="/wordDaily" element={<WordDaily />} />
            <Route exact path="/event" element={<Event />} />
            <Route exact path="/announcement" element={<Announcement />} />
            <Route exact path="/smsMessage" element={<SmsMessage />} />
            <Route exact path="/bulletin" element={<Bulletin />} />
            <Route exact path="/getStarted" element={<GetStarted />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route
              exact
              path="/https://www.admin.parishionerapp.com/login"
              element={<Home />}
            />

            <Route
              exact
              path="/terms-of-service"
              element={<TermsOfService />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Box>
      </Router>
      {/* </Provider> */}
    </ChakraProvider>
  );
}

export default App;

// display = "flex"  flexDirection = "column" justifyContent= {["center","center", "center", "center"]}
