import React from "react";
import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Heading,
  Icon,
  Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setLocation } from "../redux/actions/uiAction";

function FHero(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { heroData } = props;
  const NavButtonClick = (e, reference) => {
    e.preventDefault();
    dispatch(setLocation(reference));
    navigate(reference, { replace: true });
  };
  return (
    <Flex bg={heroData.hcolor} width="100%" direction="column" align="center">
      <Flex
        direction="column"
        bg={heroData.hcolor}
        alignItems="center"
        minH="100vh"
        width={["100%", "100%", "1200px", "1200px"]}
        marginTop={["12%", "12%", "7%", "1%"]}
      >
        <Flex
          p={(0, 0, 8, 0)}
          direction={["column-reverse", "column-reverse", "row", "row"]}
          justify="center"
          marginTop={["5%", "5%", "7%", "7%"]}
          marginBottom={[4, 4, 0, 0]}
        >
          {/* ........wordsbox */}
          <Flex
            flexDirection="column"
            mt={heroData.marT}
            maxWidth={["sm", "md", "lg", "lg"]}
            justify="center"
          >
            {/* ........Icon and Heading */}
            <Box
              mt={[20, 20, 0, 0]}
              d="flex"
              direction="row"
              justifyContent={["center", "center", "start", "start"]}
            >
              <Icon
                as={heroData.itIcon}
                fontSize="6xl"
                display={heroData.showicon}
                color="white"
                pr="4"
              />
              <Heading
                display={["none", "none", "block", "block"]}
                as="h1"
                size="2xl"
                color={heroData.wcolor}
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                {heroData.title}
              </Heading>
              <Heading
                display={["block", "block", "none", "none"]}
                as="h2"
                color={heroData.wcolor}
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                {heroData.title}
              </Heading>
              {/* <Text
                color={heroData.wcolor}
                ml={2}
                as="b"
                fontSize={["4xl", "4xl", "5xl", "5xl"]}
              >
                {heroData.title}
              </Text> */}
            </Box>
            <Text
              as="b"
              color={heroData.wcolor}
              lineHeight="120%"
              mt={6}
              fontSize={["45px", "45px", "50px", "50px"]}
              textAlign={["center", "center", "start", "start"]}
            >
              {heroData.body1}
            </Text>
            <Box d="flex" mt={7} ml={[4, 4, 0, 0]} mr={[4, 4, 0, 0]}>
              <Text
                color={heroData.wcolor}
                display="inline"
                fontSize={["25px", "25px", "27px", "27px"]}
                textAlign={["center", "center", "start", "start"]}
              >
                {heroData.body2}
              </Text>
            </Box>
            <Box
              mt={[12, 12, 4, 4]}
              d="flex"
              justifyContent={["center", "center", "start", "start"]}
            >
              <Link>
                <Button
                  // mx="20"
                  variant="solid"
                  bg="brand.100"
                  color="brand.700"
                  fontWeight={"bold"}
                  onClick={(e) => NavButtonClick(e, "/getStarted")}
                  // w={["full", "full", "max-content", "max-content"]}
                >
                  Get Started
                </Button>
              </Link>
            </Box>
          </Flex>

          {/* ........imagebox */}
          <Flex
            maxWidth={["sm", "md", "lg", "lg"]}
            display={[heroData.showimage, heroData.showimage, "flex", "flex"]}
            bg={heroData.hcolor}
            justifyContent="center"
            alignContent="center"
          >
            <Image objectFit="cover" src={heroData.heropic} alt="overviewp" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default FHero;

// /*
//   {/**.......only show on small screens */}
//   <Flex flexDirection = "column"  maxWidth={["sm", "md", "lg", "lg"]}   justify ="center" display={[heroData.showlable,heroData.showlable,"none","none"]} >
//   {/* ........Icon and Heading */}
//   <Box mt ={[20,20,0,0]} d='flex' direction='row' justifyContent = {['center','center','start','start']}>
//    <Box as={heroData.itIcon} size= '50px' display={heroData.invisible} color="white"/>
//     <Text color="white" ml={2} as="b" fontSize = {['4xl','4xl','5xl', '5xl']}>{heroData.title}</Text>
//    </Box>
//    <Text as="b" color="white" lineHeight ="120%" mt={6} fontSize = {['55px','55px','50px', '50px']} textAlign ={['center','center','start','start']}>{heroData.body1}</Text>
//    <Box d='flex' mt={5} ml={[4,4,0,0]} mr={[4,4,0,0]}>
//    <Text color="white" display='inline'  fontSize = {['25px','25px','27px', '27px']} textAlign ={['center','center','start','start']} >{heroData.body2}</Text>
//    </Box>
//    <Box mt={4} d="flex" justifyContent={["center", "center", "start", "start"]}>
//    <Button variant="fill" bg="btnblue" color="white">Get Started</Button>
//    </Box>
// </Flex>
// **/
