import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  Button,
  Input,
  Spinner,
  FormControl,
  FormLabel,
  Select,
  useColorModeValue as mode,
  Stack,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { IoLocationOutline } from "react-icons/io5";
import { GetStartedFeature } from "./fmoreComp/fMoreFeat";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearGetStartedDataResult,
  postGetStartedData,
} from "../redux/actions/dataAction";
import { useForm } from "react-hook-form";
import { resetSubscriptionType } from "../redux/actions/uiAction";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";

// const FeatureImage = (props) => (
//   <Box flex="1" {...props}>
//     <Img
//       objectFit="cover"
//       h="100%"
//       w="100%"
//       src="https://images.unsplash.com/photo-1573878737226-4f9572c22b69?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2550&q=80"
//       alt=""
//     />
//   </Box>
// );

export const GetStarted_ = () => {
  const { register, handleSubmit, reset, errors } = useForm();
  const ui = useSelector((state) => state.UI);
  const data = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const theToast = useToast();
  useEffect(() => {
    if (ui?.error.length > 0) {
      theToast({
        title: "Get Started",
        description: ui?.error,
        status: "info",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, 3000);
    }
    if (data?.responseGetStarted.length > 0) {
      theToast({
        title: "Get Started",
        description: data?.responseGetStarted,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(clearGetStartedDataResult());
      }, 3000);
    }
  }, [ui?.error, data?.responseGetStarted]);
  const onSubmit = (data, e) => {
    e.preventDefault();
    const newData = {
      plan: "Free",
      selectedPlan: data.plan,
      parishName: data.parishName,
      parishEmail: data.parishEmail,
      parishAddress: data.parishAddress,
      parishCityTown: data.parishCityTown,
      country: data.country,
      parishPriestName: data.parishPriestName,
      priestPhoneNumber: data.priestPhoneNumber,
    };
    dispatch(postGetStartedData(newData));

    reset();
  };
  const handleOptionSelectClick = () => {
    dispatch(resetSubscriptionType());
  };
  return (
    <>
      <Box as="section" bg={mode("gray.50", "gray.800")} py="24">
        <Box
          maxW={["xl", "xl", "5xl", "5xl"]}
          mx="auto"
          px={["6", "6", "8", "8"]}
          py={["6", "6", "8", "8"]}
        >
          <Flex
            justify="space-between"
            direction={["column", "column", "row", "row"]}
          >
            <Box maxW={["", "", "lg", "lg"]}>
              <Box mb={["", "", "8rem", "8rem"]}>
                <Heading
                  lineHeight="shorter"
                  size="2xl"
                  letterSpacing="tight"
                  color={mode("brand.800", "white")}
                  fontWeight="extrabold"
                >
                  We are glad your Parish is joining us
                </Heading>
                <Text
                  mt="4"
                  fontSize="2xl"
                  color={mode("brand.800", "gray.400")}
                  maxW={{
                    lg: "md",
                  }}
                >
                  Complete this form and we will quickly follow up and set your
                  Church Up.
                </Text>
              </Box>
            </Box>
            <Box
              flex="1"
              my={["14", "14", "0", "0"]}
              display={["block", "block", "block", "block"]}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing="3">
                  <FormControl id="plan">
                    <FormLabel htmlFor="plan">Subcription Type</FormLabel>
                    <Select
                      id="plan"
                      name="plan"
                      type="text"
                      //value={"hello"}
                      // defaultValue={"hello"}
                      //placeholder="Select Subcription type"
                      size="lg"
                      {...register("plan")}
                      required
                      onClick={handleOptionSelectClick}
                    >
                      {ui?.isSubscriptionType.map((subscription) => (
                        <option key={subscription.id} value={subscription.name}>
                          {subscription.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl id="parishName">
                    <Input
                      name="parishName"
                      type="text"
                      autoComplete="parishName"
                      required
                      placeholder="Parish Name"
                      size="lg"
                      {...register("parishName")}
                    />
                  </FormControl>
                  <FormControl id="parishEmail">
                    <Input
                      name="parishEmail"
                      type="email"
                      autoComplete="email"
                      required
                      placeholder="Parish Email Address"
                      size="lg"
                      {...register("parishEmail")}
                    />
                  </FormControl>
                  <FormControl id="parishAddress">
                    <Input
                      name="parishAddress"
                      type="text"
                      autoComplete="parishAddress"
                      required
                      placeholder="Parish Address"
                      size="lg"
                      {...register("parishAddress")}
                    />
                  </FormControl>
                  <FormControl id="parishCityTown">
                    <Input
                      name="parishCityTown"
                      type="text"
                      autoComplete="parishCityTown"
                      required
                      placeholder="Parish City/Town"
                      size="lg"
                      {...register("parishCityTown")}
                    />
                  </FormControl>
                  <FormControl id="country">
                    <Select
                      name="country"
                      type="text"
                      placeholder="Select Country"
                      size="lg"
                      {...register("country")}
                      required
                    >
                      <option value="Ghana">Ghana</option>
                      <option value="Nigeria">Nigeria</option>
                    </Select>
                  </FormControl>
                  <FormControl id="parishPriestName">
                    <Input
                      name="parishPriestName"
                      type="text"
                      autoComplete="parishPriestName"
                      required
                      placeholder="Parish Priest Name"
                      size="lg"
                      {...register("parishPriestName")}
                    />
                  </FormControl>
                  <FormControl id="priestPhoneNumber">
                    <Input
                      name="priestPhoneNumber"
                      type="number"
                      autoComplete="priestPhoneNumber"
                      required
                      placeholder="Parish Priest Phone Number"
                      size="lg"
                      {...register("priestPhoneNumber")}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    bg="brand.700"
                    colorScheme="white"
                    size="lg"
                    fontSize="md"
                  >
                    {ui?.loading === "true" ? (
                      <Spinner size="xs" />
                    ) : (
                      "Send Request"
                    )}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Flex>
          <Heading
            color={"brand.800"}
            fontWeight={"extrabold"}
            size={"xl"}
            my="10"
          >
            Contact Us
          </Heading>
          <SimpleGrid
            flex="1"
            mt="30"
            columns={[1, 1, 3, 3]}
            spacing={["3rem", "3rem", "2rem", "2rem"]}
          >
            <GetStartedFeature title="Email" icon={<HiOutlineMailOpen />}>
              help@parishionerapp.com
            </GetStartedFeature>
            <GetStartedFeature title="Call or WhatApp" icon={<BiPhoneCall />}>
              0246863218
            </GetStartedFeature>
            <GetStartedFeature title="Address" icon={<IoLocationOutline />}>
              GQ155 Cherubim Ave
            </GetStartedFeature>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
};
