import { Box, Divider, Flex, Stack } from "@chakra-ui/react";
import * as React from "react";
import { BsCalendar4Event } from "react-icons/bs";
import { FaSms, FaChurch, FaBookReader } from "react-icons/fa";
import { GiNewspaper } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { BiMessageRoundedDots } from "react-icons/bi";
import { IoPricetagsOutline } from "react-icons/io5";
import { GrContactInfo } from "react-icons/gr";
import { ImBullhorn } from "react-icons/im";
import { MdOutlineFeaturedPlayList, MdOutlineLogout } from "react-icons/md";
import Logo from "../../util/logo";
import { NavButton } from "./navButton";

export const Sidebar = (props) => {
  const { onClose } = props;
  const sup = "12";
  return (
    <Flex as="section" minH="100vh">
      <Box
        bg="brand.50"
        color="on-accent"
        w="full"
        py={{
          base: "6",
          sm: "8",
        }}
        px={{
          base: "4",
          sm: "6",
        }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack
            spacing={{
              base: "5",
              sm: "6",
            }}
            shouldWrapChildren
          >
            <Logo
              px="4"
              h="8"
              mt="3"
              mb={{
                base: "10",
                md: "5",
              }}
              iconColor="brand.700"
              color="brand.700"
            />
            <Stack spacing="1" pt="10" w="full">
              <NavButton label="Home" icon={AiOutlineHome} onClose={onClose} />

              <NavButton
                label="Features"
                icon={MdOutlineFeaturedPlayList}
                aria-current="page"
                onClose={onClose}
              />
              <Stack>
                <NavButton
                  label="Messaging"
                  icon={BiMessageRoundedDots}
                  onClose={onClose}
                  sup={sup}
                  color="yyellow"
                />
                <NavButton
                  label="My Parish"
                  icon={FaChurch}
                  onClose={onClose}
                  sup={sup}
                  color="ggreen"
                />
                <NavButton
                  label="Parish Event"
                  icon={BsCalendar4Event}
                  onClose={onClose}
                  sup={sup}
                  color={"ppurple"}
                />
                <NavButton
                  label="Announcement"
                  icon={ImBullhorn}
                  onClose={onClose}
                  sup={sup}
                  color={"darkblue"}
                />
                <NavButton
                  label="SMS Messaging"
                  icon={FaSms}
                  onClose={onClose}
                  sup={sup}
                  color={"ppink"}
                />
                <NavButton
                  label="Word Daily"
                  icon={FaBookReader}
                  onClose={onClose}
                  sup={sup}
                  color={"rred"}
                />
                <NavButton
                  label="Bulletin"
                  icon={GiNewspaper}
                  onClose={onClose}
                  sup={sup}
                  color={"ggray"}
                />
              </Stack>
              <NavButton
                label="Pricing"
                icon={IoPricetagsOutline}
                onClose={onClose}
              />
              <NavButton
                label="Contact Us"
                icon={GrContactInfo}
                onClose={onClose}
              />
              <Divider />
              <NavButton
                label="Login"
                icon={MdOutlineLogout}
                onClose={onClose}
              />
            </Stack>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              sm: "6",
            }}
          >
            <Divider />
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
};

{
  /* <Logo
mx="auto"
h="8"
mb={{
  base: "10",
  md: "5",
}}
iconColor="brand.700"
color="brand.700"
/> */
}
