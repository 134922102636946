import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { PricingCard } from "./PricingCard";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setSubscriptionType } from "../../redux/actions/uiAction";

export const Pricing_ = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGetStartedClick = (e, data) => {
    e.preventDefault();
    dispatch(setSubscriptionType(data));
    navigate("/getStarted", { replace: true });
  };
  return (
    <Box as="section" bg={useColorModeValue("brand.100", "gray.800")} py="24">
      <Box
        maxW={["xl", "xl", "5xl", "5xl"]}
        mx="auto"
        px={["6", "6", "8", "8"]}
      >
        <Box textAlign={["center", "center", "start", "start"]}>
          <Heading
            size="2xl"
            lineHeight="normal"
            color={useColorModeValue("brand.800", "blue.400")}
            letterSpacing="tight"
            fontWeight="extrabold"
          >
            Plans and Pricing
          </Heading>
          <Text
            fontSize={["xl", "xl", "2xl", "2xl"]}
            mt="4"
            maxW="2xl"
            mx={["auto", "auto", "auto", "unset"]}
          >
            Join the Many Churches who are using Parishioner App.
          </Text>
        </Box>
        <SimpleGrid mt="10" columns={[1, 1, 3, 3]} spacing="10">
          <PricingCard
            bg="brand.700"
            color="white"
            button={
              <Button
                w="full"
                size="lg"
                h="16"
                color="revert"
                onClick={(e) => handleGetStartedClick(e, "Free")}
              >
                Get Started
              </Button>
            }
            data={{
              name: "Free",
              description: "For Small Catholic Parishes",
              price: 0,
              duration: "monthly",
              benefits: ["Announcement", "Daily Reading", "My Parish"],
            }}
          />
          <PricingCard
            bg="brand.700"
            color="white"
            button={
              <Button
                w="full"
                h="16"
                color="revert"
                size="lg"
                onClick={(e) => handleGetStartedClick(e, "Standard")}
              >
                Get Started
              </Button>
            }
            data={{
              name: "Standard",
              description: "For Large Catholic Parishes",
              price: 99,
              duration: "monthly",
              benefits: [
                "Messaging (InApp)",
                "Parish Event",
                "Announcement",
                "My Parish",
                "Daily Readings",
                "Parish Bulletin",
              ],
            }}
          />
          <PricingCard
            bg="brand.800"
            color="white"
            button={
              <Button
                isDisabled
                w="full"
                color="revert"
                h="16"
                size="lg"
                onClick={(e) => handleGetStartedClick(e, "plus")}
              >
                Not Available
              </Button>
            }
            data={{
              name: "Plus",
              description: "For large Parishes who wants more",
              price: 199,
              duration: "monthly",
              benefits: [
                "Customized Parish App",
                "SMS Messaging",
                "Parish Bulletin",
                "Messaging (InApp)",
                "Parish Event",
                "Announcement",
                "Daily Readings",
                "My Parish",
              ],
            }}
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
};
