import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Link,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  HStack,
} from "@chakra-ui/react";
import { BiMessageRoundedDots } from "react-icons/bi";
import { BsCalendar4Event, BsInputCursor } from "react-icons/bs";
import { FaChurch, FaSms, FaBookReader } from "react-icons/fa";
import { ImBullhorn } from "react-icons/im";
import { GiNewspaper } from "react-icons/gi";
import { ToggleButton } from "./navComp/ToggleButton";
import { Sidebar } from "./navComp/sidebar";
import Logo from "../util/logo";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { resetSubscriptionType, setLocation } from "../redux/actions/uiAction";
function Navbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLocation = useSelector((state) => state.UI.isLocation);
  const location = useLocation();
  //const navColor= null;
  const [navColor, setnavColor] = useState("");
  const [navLogo, setnavLogo] = useState("");
  const [loginColor, setloginColor] = useState("");
  const [wordColor, setwordColor] = useState("");
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const isMobilOrDesktopLocation = useBreakpointValue({
    base: isLocation,
    lg: location.pathname,
  });
  const { isOpen, onToggle, onClose } = useDisclosure();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setnavColor("wwhite");
        setloginColor("btnblue");
        setwordColor("darkblue");
        setnavLogo("brand.700");
        break;
      case "/message":
        setnavColor("yyellow");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/myparish":
        setnavColor("ggreen");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/wordDaily":
        setnavColor("rred");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/event":
        setnavColor("ppurple");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/announcement":
        setnavColor("paleblue");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/smsMessage":
        setnavColor("ppink");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/bulletin":
        setnavColor("ggray");
        setloginColor("white");
        setwordColor("white");
        setnavLogo("white");
        break;
      case "/getStarted":
        setnavColor("wwhite");
        setloginColor("btnblue");
        setwordColor("darkblue");
        setnavLogo("brand.700");
        break;
      case "/pricing":
        setnavColor("wwhite");
        setloginColor("darkblue");
        setwordColor("darkblue");
        setnavLogo("brand.700");
        break;
      case "/privacy-policy":
        setnavColor("wwhite");
        setloginColor("darkblue");
        setwordColor("darkblue");
        setnavLogo("brand.700");
        break;
      case " /terms-of-service":
        setnavColor("wwhite");
        setloginColor("darkblue");
        setwordColor("darkblue");
        setnavLogo("brand.700");
        break;

      default:
        break;
    }
  }, [isLocation]);

  const NavButtonClick = (e, reference) => {
    e.preventDefault();
    if (reference === "https://www.admin.parishionerapp.com/login") {
      // dispatch(resetSubscriptionType());
      // dispatch(setLocation("/"));
      // navigate("/", { replace: true });
      window.open(reference, "_blank");
      location.push("/");
    } else {
    }
    dispatch(resetSubscriptionType());
    dispatch(setLocation(reference));
    navigate(reference, { replace: true });
  };

  //const navColor = props;
  return (
    <Box
      w="100%"
      h="60px"
      borderBottom={2}
      bg={navColor}
      //color={loginColor}
      position="fixed"
      display="inline-block"
      zIndex={1}
      boxShadow="3px #ffff"
      padding={["10px", "10px", "5px", "5px"]}
    >
      <Box
        justifyContent={[
          "space-between",
          "space-between",
          "space-around",
          "space-around",
        ]}
        mr={[1, 6, 8, 10]}
        ml={[1, 6, 8, 10]}
        bg={navColor}
        d="flex"
      >
        <Box d="flex" alignItems="center">
          <Link>
            <Logo
              mx="auto"
              h="8"
              mb={{
                base: "10",
                md: "5",
              }}
              iconColor={navLogo}
              color={"blue"}
              onClick={(e) => NavButtonClick(e, "/")}
            />
          </Link>
        </Box>

        <Box
          d={["none", "none", "flex", "flex"]}
          alignItems="center"
          justify={[
            "space-between",
            "space-between",
            "center",
            "space-between",
          ]}
        >
          <Box>
            {" "}
            <Link>
              <Button
                variant="ghost"
                color={wordColor}
                onClick={(e) => NavButtonClick(e, "/")}
              >
                Home
              </Button>
            </Link>
          </Box>
          <Box ml={2} display="flex" alignItems="center">
            {" "}
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                variant="ghost"
                color={wordColor}
              >
                Features
              </MenuButton>
              <MenuList>
                <Link>
                  <MenuItem
                    height={12}
                    w="xl"
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/message")}
                  >
                    {" "}
                    <HStack px="10" spacing={"8"}>
                      <Icon
                        color="yyellow"
                        fontSize={"2xl"}
                        as={BiMessageRoundedDots}
                      />
                      <Text fontWeight={"bold"} color="brand.800">
                        {" "}
                        Messaging
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/myparish")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon color="ggreen" fontSize={"2xl"} as={FaChurch} />
                      <Text fontWeight={"bold"} color="brand.800">
                        {" "}
                        My Parish
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/wordDaily")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon color="rred" fontSize={"2xl"} as={FaBookReader} />
                      <Text fontWeight={"bold"} color="brand.800">
                        Word Daily
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/event")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon
                        color="ppurple"
                        fontSize={"2xl"}
                        as={BsCalendar4Event}
                      />
                      <Text fontWeight={"bold"} color="brand.800">
                        Parish Event
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/announcement")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon color="#7D75EA" fontSize={"2xl"} as={ImBullhorn} />
                      <Text fontWeight={"bold"} color="brand.800">
                        Announcement
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/smsMessage")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon color="ppink" fontSize="2xl" as={FaSms} />
                      <Text fontWeight={"bold"} color="brand.800">
                        SMS Messaging
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <Divider />
                <Link>
                  <MenuItem
                    height={12}
                    color="darkblue"
                    onClick={(e) => NavButtonClick(e, "/bulletin")}
                  >
                    <HStack px="10" spacing={"8"}>
                      <Icon color="ggray" fontSize={"2xl"} as={GiNewspaper} />
                      <Text fontWeight={"bold"} color="brand.800">
                        {" "}
                        Bulletin
                      </Text>
                    </HStack>
                  </MenuItem>
                </Link>
              </MenuList>
            </Menu>
          </Box>
          <Box ml={2}>
            {" "}
            <Link>
              <Button
                variant="ghost"
                color={wordColor}
                onClick={(e) => NavButtonClick(e, "/pricing")}
              >
                Pricing
              </Button>
            </Link>
          </Box>
          <Box ml={2}>
            {" "}
            <Link>
              <Button
                variant="ghost"
                color={wordColor}
                onClick={(e) => NavButtonClick(e, "/getStarted")}
              >
                Contact Us
              </Button>
            </Link>
          </Box>
        </Box>

        <Box alignItems="center" d="flex">
          <Link>
            <Button
              variant="outline"
              size="sm"
              mr="6"
              display={["inline", "inline", "inline", "inline"]}
              onClick={(e) =>
                NavButtonClick(e, "https://www.admin.parishionerapp.com/login")
              }
              borderColor={wordColor}
              color={wordColor}
            >
              Log in
            </Button>
          </Link>
          <Box>
            <>
              <ToggleButton
                isOpen={isOpen}
                aria-label="Open Menu"
                onClick={onToggle}
                color={navLogo}
                display={["inline", "inline", "none", "none"]}
              />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap // Only disabled for showcase
                trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <Sidebar onClose={onClose} />
                </DrawerContent>
              </Drawer>
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Navbar;

/*
<Menu >
            <MenuButton _hover = {{MenuList:"none"}} as={Button} rightIcon = "chevron-down" colorScheme="teal" variant="ghost" color ="white" >
            Features 
          </MenuButton>
          <MenuList >
             <MenuItem>
              <Box  size = {6} mr ={6}  as ={MdMessage}/>
              <Text> Messaging</Text>
             </MenuItem>
          </MenuList>
          </Menu>
*/

/*
<Button  rightIcon = "chevron-down" colorScheme="teal" variant="ghost" color ="white" _hover={{display: "inline-block"}}  >
            Features 
          </Button>
          <PseudoBox  as={Button} _hover={{display: "flex"}} display = "none"   position = "absolute" bg = "red" width = {8} zIndex = {1}   >
          <a href="#" display = "block" >Link 1</a>
          <a href="#" display = "block">Link 2</a>
          <a href="#" display = "block">Link 3</a>
        </PseudoBox>
          
*/
