import { Flex } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import TermsOfService_ from "../components/TermsOfService_";

function TermsOfService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex direction="column">
      <TermsOfService_ />
      <Footer_ />
    </Flex>
  );
}

export default TermsOfService;
