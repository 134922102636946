import { CLEAR_POSTGETSTARTEDDATA_RESULT, POST_GETSTARTEDDATA } from "../types";

const initialState = {
  responseGetStarted: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POST_GETSTARTEDDATA:
      return {
        ...state,
        responseGetStarted: action.payload,
      };
    case CLEAR_POSTGETSTARTEDDATA_RESULT:
      return {
        ...state,
        responseGetStarted: "",
      };
    default:
      return state;
  }
}
