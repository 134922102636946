import React from "react";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { GetStarted_ } from "../components/GetStarted_";

function GetStarted() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <GetStarted_ />
      <Footer_ />
    </div>
  );
}

export default GetStarted;
