import {
  Box,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import overviewpic from "../images/overview1.jpg";

export const Overview_ = () => {
  return (
    <Box as="section">
      <Box
        maxW={["xl", "xl", "7xl", "7xl"]}
        mx="auto"
        px={["6", "6", "8", "8"]}
        py="16"
      >
        <Flex
          align="center"
          justify="center"
          direction={["column-reverse", "column-reverse", "row", "row"]}
        >
          <Box flex="1" maxW="440px" pt="10">
            <Heading
              size="xl"
              fontWeight="extrabold"
              color={useColorModeValue("brand.800", "blue.300")}
            >
              The Most Effective way to Engage Parishioners
            </Heading>
            <Stack spacing="4">
              <Text
                fontSize="lg"
                fontWeight="normal"
                color={useColorModeValue("brand.800", "inherit")}
                mt="6"
              >
                With most people using smartphones, Engaging them with a tool
                such as Parishioner App will bring effectiveness and convenience
                to the way your Church communicate with her members.
              </Text>
              <Text
                fontSize="lg"
                fontWeight="normal"
                color={useColorModeValue("brand.800", "inherit")}
                mt="6"
              >
                With an easy to use platform, Churches can send In App Messages,
                Announcements and SMS Messages to parishioners. They can set
                Events, Mass Time and Confession Time.
              </Text>
            </Stack>

            {/* <Stack
              mt={{
                base: "8",
                md: "16",
              }}
              spacing="4"
              fontWeight="extrabold"
            >
              <HStack
                spacing="5"
                color={useColorModeValue("blue.600", "blue.300")}
              >
                <Box fontSize="3xl" as={MdPermPhoneMsg} />
                <Text
                  fontSize={{
                    base: "xl",
                    md: "2xl",
                  }}
                >
                  1 800 513 4545
                </Text>
              </HStack>
              <HStack
                spacing="5"
                color={useColorModeValue("blue.600", "blue.300")}
              >
                <Box fontSize="3xl" as={MdGroup} />
                <Text
                  fontSize={{
                    base: "xl",
                    md: "2xl",
                  }}
                >
                  Free design consultations
                </Text>
              </HStack>
            </Stack> */}
          </Box>

          <Box
            aria-hidden
            className="spacer"
            flexShrink={0}
            boxSize={{
              base: "10",
              xl: "20",
            }}
          />

          <Box
            flex="1"
            maxW="560px"
            h={{
              base: "400px",
              md: "460px",
            }}
          >
            <Img
              alt="Call center"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition="right"
              src={overviewpic}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
