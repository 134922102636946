import React from "react";
import FHero from "../components/FHero";
import { FaChurch } from "react-icons/fa";
import myp_p from "../images/myp_p.png";
import myparishHero from "../images/myparishHero.png";
import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function MyParish() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "My Parish",
    body1: "All about your Parish in One Place",
    body2:
      "Find more about your Parish such as: Parish Mass Times, Confession Times and Parish contact details",
    itIcon: FaChurch,
    hcolor: "#36A10F",
    heropic: myparishHero,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: myp_p,
    textP0: "Making It Easy for Churches to Engage with their community",
    textP1Title: "About your Church",
    textP1:
      "Get to know important information about your Parish such as: contact details, name of priest, Parish location",
    textP2Title: "Be Informed",
    textP2:
      "Know from Parishioner App the day and time your Parish celebrates Mass. Know from Parishioner App the Days and Times your Parish Priest listens to confession",
    textP3: "",
  };
  return (
    <Box display="flex" flexDirection="column">
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </Box>
  );
}

export default MyParish;
