import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function TermsOfService_() {
  return (
    <Box as="section" bg={useColorModeValue("brand.100", "gray.800")} py="24">
      <Box
        maxW={["xl", "xl", "5xl", "5xl"]}
        mx="auto"
        px={["6", "6", "8", "8"]}
      >
        {/* <Box
          textAlign={["start", "start", "start", "start"]}
          mt={["20%", "20%", "4%", "4%"]}
          mx={["auto", "auto", "auto", "unset"]}
        > */}
        <Stack mt="4" spacing={"4"}>
          <Heading size={"lg"} color="brand.600">
            Website Terms and Conditions of Use
          </Heading>
          <Heading color="brand.600" size={"md"}>
            1. Terms
          </Heading>
          <Text>
            By accessing this Website, accessible from
            https://www.parishionerapp.com, you are agreeing to be bound by
            these Website Terms and Conditions of Use and agree that you are
            responsible for the agreement with any applicable local laws. If you
            disagree with any of these terms, you are prohibited from accessing
            this site. The materials contained in this Website are protected by
            copyright and trade mark law.
          </Text>
          <Heading color="brand.600" size="md">
            2. Use License
          </Heading>
          <Text>
            Permission is granted to temporarily download one copy of the
            materials on Parishioner HQ's Website for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a
            transfer of title, and under this license you may not:
          </Text>

          <UnorderedList pt={"2"}>
            <ListItem>modify or copy the materials;</ListItem>
            <ListItem>
              use the materials for any commercial purpose or for any public
              display;
            </ListItem>
            <ListItem>
              {" "}
              attempt to reverse engineer any software contained on Parishioner
              HQ's Website;
            </ListItem>
            <ListItem>
              remove any copyright or other proprietary notations from the
              materials; or
            </ListItem>
            <ListItem>
              transferring the materials to another person or "mirror" the
              materials on any other server.
            </ListItem>
          </UnorderedList>

          <Text>
            This will let Parishioner HQ to terminate upon violations of any of
            these restrictions. Upon termination, your viewing right will also
            be terminated and you should destroy any downloaded materials in
            your possession whether it is printed or electronic format.
          </Text>

          <Heading color="brand.600" size="md">
            3. Disclaimer
          </Heading>
          <Text>
            All the materials on Parishioner HQ’s Website are provided "as is".
            Parishioner HQ makes no warranties, may it be expressed or implied,
            therefore negates all other warranties. Furthermore, Parishioner HQ
            does not make any representations concerning the accuracy or
            reliability of the use of the materials on its Website or otherwise
            relating to such materials or any sites linked to this Website.
          </Text>
          <Heading color="brand.600" size="md">
            4. Limitations
          </Heading>
          <p>
            Parishioner HQ or its suppliers will not be hold accountable for any
            damages that will arise with the use or inability to use the
            materials on Parishioner HQ’s Website, even if Parishioner HQ or an
            authorize representative of this Website has been notified, orally
            or written, of the possibility of such damage. Some jurisdiction
            does not allow limitations on implied warranties or limitations of
            liability for incidental damages, these limitations may not apply to
            you.
          </p>
          <Heading color="brand.600" size="md">
            5. Revisions and Errata
          </Heading>

          <p>
            The materials appearing on Parishioner HQ’s Website may include
            technical, typographical, or photographic errors. Parishioner HQ
            will not promise that any of the materials in this Website are
            accurate, complete, or current. Parishioner HQ may change the
            materials contained on its Website at any time without notice.
            Parishioner HQ does not make any commitment to update the materials.
          </p>

          <Heading color="brand.600" size="md">
            6. Links
          </Heading>

          <p>
            Parishioner HQ has not reviewed all of the sites linked to its
            Website and is not responsible for the contents of any such linked
            site. The presence of any link does not imply endorsement by
            Parishioner HQ of the site. The use of any linked website is at the
            user’s own risk.
          </p>

          <Heading color="brand.600" size="md">
            7. Site Terms of Use Modifications
          </Heading>

          <p>
            Parishioner HQ may revise these Terms of Use for its Website at any
            time without prior notice. By using this Website, you are agreeing
            to be bound by the current version of these Terms and Conditions of
            Use.
          </p>
          <Heading color="brand.600" size="md">
            8. Your Privacy
          </Heading>

          <p>Please read our Privacy Policy.</p>

          <Heading color="brand.600" size="md">
            9. Governing Law
          </Heading>

          <p>
            Any claim related to Parishioner HQ's Website shall be governed by
            the laws of gh without regards to its conflict of law provisions.
          </p>
        </Stack>
      </Box>
      {/* </Box> */}
    </Box>
  );
}

export default TermsOfService_;
