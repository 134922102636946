import React from "react";
import FHero from "../components/FHero";
import bull_p from "../images/bulletinhmore.png";
import bull_h from "../images/bull_h.png";
import { GiNewspaper } from "react-icons/gi";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function Bulletin() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Parish Bulletin",
    body1: "Access your Parish Weekly Bulletin",
    body2:
      "Stay close to what is happening in your Church. Access your Parish weekly bulletin on Parishioner App",
    itIcon: GiNewspaper,
    hcolor: "ggray",
    heropic: bull_h,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: bull_p,
    textP0: "Keep Parishioners Informed on all Important Information",
    textP1Title: "Keep Track",
    textP1:
      "With all weekly bulletin in one place, easily keep track of stories, information found in your Parish weekly bulletin",
    textP2Title: "Download Bulletin",
    textP2:
      "Download the bulletin in pdf format, share and access it outside the Parishioner App.",
    textP3: "",
  };

  return (
    <div>
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </div>
  );
}

export default Bulletin;
