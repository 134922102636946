import {
  Box,
  Flex,
  HStack,
  LightMode,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import * as React from "react";

const BillingBadge = (props) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
    {...props}
  />
);

export const PricingCard = (props) => {
  const { button, data, ...rest } = props;
  const { name, description, price, benefits } = data;
  const isFree = price == 0;
  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="sm"
      pos="relative"
      w="90%"
      mx="auto"
      {...rest}
    >
      <Box flex="1">
        {!isFree && <BillingBadge>Billed monthly</BillingBadge>}
        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>
        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <HStack spacing={"1"}>
            <Text fontSize={["sm", "sm", "md", "md"]}>GHS</Text>
            <Text
              fontSize={["6xl", "6xl", "7xl", "7xl"]}
              lineHeight="1"
              flexShrink={0}
            >
              {price}
            </Text>
          </HStack>
        </Flex>
        <Box mt="6">
          <Text fontSize="xl" fontWeight="semibold" mb="6">
            {description}
          </Text>
          <UnorderedList spacing="1">
            {benefits.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="10">
        <LightMode>{button}</LightMode>
      </Box>
    </Flex>
  );
};
