import {
  POST_GETSTARTEDDATA,
  LOADING_UI,
  SET_ERRORS,
  CLEAR_ERRORS,
  END_LOADING_UI,
  CLEAR_POSTGETSTARTEDDATA_RESULT,
  SET_TOAST,
} from "../types";
import axios from "axios";

export const postGetStartedData = (data) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/getStartedData", data)
    .then((res) => {
      dispatch({
        type: POST_GETSTARTEDDATA,
        payload: res.data,
      });
      dispatch(clearErrors());
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
      dispatch({ type: END_LOADING_UI });
      // dispatch(clearErrors());
    });
};

export const clearGetStartedDataResult = () => (dispatch) => {
  dispatch({ type: CLEAR_POSTGETSTARTEDDATA_RESULT });
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
