import React from "react";
import { Box } from "@chakra-ui/react";
import hmhero from "../images/hmhero2.png";
import FHero from "../components/FHero";

//component
import { Footer_ } from "../components/Footer_";
import { Overview_ } from "../components/Overview_";
import { Action_ } from "../components/Action_";
import { Features_ } from "../components/featuresComp/Features_";
import { useEffect } from "react";
import { Pricing_ } from "../components/pricingComp/Pricing_";

function Home() {
  //itIcon: FaSms,
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Parishioner App",
    body1: "",
    body2:
      "The App for Catholic Churches to Stay connected with their Parishioners",
    hcolor: "wwhite",
    heropic: hmhero,
    showicon: "none",
    marT: "-16px",
    showimage: "",
    wcolor: "brand.800",
  };

  return (
    <Box display="flex" flexDirection="column">
      <FHero heroData={heroData} />
      <Overview_ />
      <Action_ />
      <Features_ />
      <Pricing_ />
      <Footer_ />
    </Box>
  );
}

export default Home;
