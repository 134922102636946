import { Button, HStack, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  resetSubscriptionType,
  setLocation,
} from "../../redux/actions/uiAction";

export const NavButton = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { icon, label, onClose, mne, sup, color, ...buttonProps } = props;
  const navButtonClick = (e, label, onClose) => {
    e.preventDefault();
    dispatch(resetSubscriptionType());
    switch (label) {
      case "Messaging":
        dispatch(setLocation("/message"));
        navigate("/message", { replace: true });
        onClose();
        break;
      case "Home":
        dispatch(setLocation("/"));
        navigate("/", { replace: true });
        onClose();
        break;
      case "My Parish":
        dispatch(setLocation("/myparish"));
        navigate("/myparish", { replace: true });
        onClose();
        break;
      case "Word Daily":
        dispatch(setLocation("/wordDaily"));
        navigate("/wordDaily", { replace: true });
        onClose();
        break;
      case "Parish Event":
        dispatch(setLocation("/event"));
        navigate("/event", { replace: true });
        onClose();
        break;
      case "Announcement":
        dispatch(setLocation("/announcement"));
        navigate("/announcement", { replace: true });
        onClose();
        break;
      case "SMS Messaging":
        dispatch(setLocation("/smsMessage"));
        navigate("/smsMessage", { replace: true });
        onClose();
        break;
      case "Bulletin":
        dispatch(setLocation("/bulletin"));
        navigate("/bulletin", { replace: true });
        onClose();
        break;
      case "Pricing":
        dispatch(setLocation("/pricing"));
        navigate("/pricing", { replace: true });
        onClose();
        break;
      case "Contact Us":
        dispatch(setLocation("/getStarted"));
        navigate("/getStarted", { replace: true });
        onClose();
        break;
      case "Login":
        window.open("https://www.admin.parishionerapp.com/login", "_blank");
        onClose();
        break;

      default:
        break;
    }
  };
  return (
    <Button
      variant="ghost"
      // borderColor="brand.800"
      // boxShadow="none"
      justifyContent="start"
      {...buttonProps}
      px={sup}
      w="full"
      onClick={(e) => navButtonClick(e, label, onClose)}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" color={color} />
        <Text color="brand.800">{label}</Text>
      </HStack>
    </Button>
  );
};
