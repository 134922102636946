import React from "react";
import { Box } from "@chakra-ui/react";
import { Overview_ } from "../components/Overview_";

//component

function Overview() {
  return (
    <Box>
      <Overview_ />
    </Box>
  );
}

export default Overview;
