import {
  Box,
  HStack,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";

export const FMoreFeat = (props) => {
  const { icon, title, children } = props;
  return (
    <Box>
      <HStack spacing="3" color={mode("brand.600", "blue.300")}>
        <Box as={icon} fontSize="xl" />
        <Text fontWeight="extrabold" fontSize="lg">
          {title}
        </Text>
      </HStack>
      <Text mt="4" fontSize="lg" color={mode("brand.800", "inherit")}>
        {children}
      </Text>
    </Box>
  );
};

export const GetStartedFeature = (props) => {
  const { title, children, icon } = props;
  return (
    <Box>
      <Box color={mode("brand.700", "blue.400")} fontSize="2rem">
        {icon}
      </Box>
      <Stack mt="4">
        <Text
          as="h3"
          color={mode("brand.700", "blue.400")}
          fontSize="xl"
          fontWeight="bold"
        >
          {title}
        </Text>
        <Text paddingEnd="6" lineHeight="tall">
          {children}
        </Text>
      </Stack>
    </Box>
  );
};
