//Data Types
export const POST_GETSTARTEDDATA = "POST_GETSTARTEDDATA";

//UI Types
export const LOADING_UI = "LOADING_UI";
export const END_LOADING_UI = "END_LOADING_UI";
export const SET_LOCATION = "SET_LOCATION";
export const SET_SUBSCRIPTION_TYPE = "SET_SUBSCRIPTION_TYPE";
export const RESET_SUBSCRIPTION_TYPE = "RESET_SUBSCRIPTION_TYPE";
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_POSTGETSTARTEDDATA_RESULT =
  "CLEAR_POSTGETSTARTEDDATA_RESULT";
export const OPEN_TOAST = "OPEN_TOAST";
