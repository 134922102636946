import React from "react";
import FHero from "../components/FHero";
import { FaBookReader } from "react-icons/fa";
import dwd_p from "../images/dwd_p.png";
import wordHero from "../images/wordHero.png";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import { FMore_ } from "../components/fmoreComp/FMore_";

function WordDaily() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroData = {
    title: "Word Daily",
    body1: "Read the word of God Daily",
    body2:
      '"' +
      "Your word is a lamp for my feet, a light on my Path Psalm 119:103" +
      '"',
    itIcon: FaBookReader,
    hcolor: "rred",
    heropic: wordHero,
    showicon: "",
    showimage: "none",
    wcolor: "white",
  };

  const moreData = {
    morepic: dwd_p,
    textP0: "Making It Easy for Churches to Engage with their community",
    textP1Title: "Word of God",
    textP1: "Parishioner App features Daily Mass Readings.",
    textP2Title: "Share the Word",
    textP2:
      "Bookmark your favorite daily readings and Share readings to friends on other platforms",
    textP3: "",
  };
  return (
    <div>
      <FHero heroData={heroData} />
      <FMore_ moreData={moreData} />
      <Footer_ />
    </div>
  );
}

export default WordDaily;
