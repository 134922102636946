import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

function Privacy_() {
  return (
    <Box as="section" bg={useColorModeValue("brand.100", "gray.800")} py="24">
      <Box
        maxW={["xl", "xl", "5xl", "5xl"]}
        mx="auto"
        px={["6", "6", "8", "8"]}
      >
        {/* <Box
          textAlign={["start", "start", "start", "start"]}
          mt={["20%", "20%", "4%", "4%"]}
          mx={["auto", "auto", "auto", "unset"]}
        > */}
        <Heading
          pt="4"
          size={"md"}
          fontSize={["xl", "xl", "2xl", "2xl"]}
          color="brand.600"
          maxW={{
            lg: "md",
          }}
        >
          Privacy Policy for Parishioner App
        </Heading>
        <Text
          pt={"4"}
          fontSize="md"
          maxW={{
            lg: "md",
          }}
        >
          At Parishioner_HQ, accessible from https://www.parishionerapp.com, one
          of our main priorities is the privacy of our visitors. This Privacy
          Policy document contains types of information that is collected and
          recorded by parishionerapp and how we use it.
        </Text>

        <Text pt={"4"} fontSize="md">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </Text>

        <Text pt={"4"} fontSize="md">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in parishionerapp. This policy is not applicable
          to any information collected offline or via channels other than this
          website.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Consent
        </Heading>
        <Text pt={"4"} fontSize="md">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Information we collect
        </Heading>

        <Text pt={"4"} fontSize="md">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </Text>
        <Text pt={"4"} fontSize="md">
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </Text>

        <Text pt={"4"} fontSize="md">
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          How we use your information
        </Heading>
        <Text pt={"4"} fontSize="md">
          We use the information we collect in various ways, including to:
        </Text>

        <UnorderedList pt={"2"}>
          <ListItem>Provide, operate, and maintain our website</ListItem>
          <ListItem>Improve, personalize, and expand our website</ListItem>
          <ListItem>Understand and analyze how you use our website</ListItem>
          <ListItem>
            Develop new products, services, features, and functionality
          </ListItem>
          <ListItem>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </ListItem>
          <ListItem>Send you emails</ListItem>
          <ListItem>Find and prevent fraud</ListItem>
        </UnorderedList>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Log Files
        </Heading>

        <Text pt={"4"} fontSize="md">
          parishionerapp follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services' analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Cookies and Web Beacons
        </Heading>

        <Text pt={"4"} fontSize="md">
          Like any other website, parishionerapp uses 'cookies'. These cookies
          are used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </Text>
        <Text pt={"4"} fontSize="md">
          For more general information on cookies, please read{" "}
          <a href="https://www.termsfeed.com/blog/sample-cookies-policy-template/#What_Are_Cookies">
            the Cookies article on TermsFeed website
          </a>
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Advertising Partners Privacy Policies
        </Heading>
        <Text pt={"4"} fontSize="md">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of parishionerapp.
        </Text>
        <Text pt={"4"} fontSize="md">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on parishionerapp, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit. Note that
          parishionerapp has no access to or control over these cookies that are
          used by third-party advertisers.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Third Party Privacy Policies
        </Heading>

        <Text pt={"4"} fontSize="md">
          parishionerapp's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </Text>
        <Text pt={"4"} fontSize="md">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </Heading>
        <Text pt={"4"} fontSize="md">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </Text>
        <Text pt={"4"} fontSize="md">
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </Text>
        <Text pt={"4"} fontSize="md">
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </Text>
        <Text pt={"4"} fontSize="md">
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </Text>
        <Text pt={"4"} fontSize="md">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </Text>

        <Heading size={"md"} pt={"6"} color="brand.600">
          GDPR Data Protection Rights
        </Heading>
        <Stack pt="4" spacing={"4"}>
          <Text>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </Text>
          <Text>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </Text>
          <Text>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </Text>

          <Text>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </Text>
          <Text>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </Text>
          <Text>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </Text>
          <Text>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </Text>
          <Text>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </Text>
        </Stack>

        <Heading size={"md"} pt={"6"} color="brand.600">
          Children's Information
        </Heading>
        <Stack pt="4" spacing={"4"}>
          <Text>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </Text>
          <Text>
            parishionerapp does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </Text>
        </Stack>
      </Box>
      {/* </Box> */}
    </Box>
  );
}
export default Privacy_;
