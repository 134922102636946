import React from "react";
import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";

//component
import PricingC from "../components/pricing";
import { Footer_ } from "../components/Footer_";
import { Pricing_ } from "../components/pricingComp/Pricing_";

function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex direction="column">
      <Pricing_ />
      <Footer_ />
    </Flex>
  );
}

export default Pricing;
