import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { FiArrowRight } from "react-icons/fi";
import { features } from "./featuresData";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setLocation } from "../../redux/actions/uiAction";

export const Features_ = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const LearnMoreClick = (e, reference) => {
    e.preventDefault();
    dispatch(setLocation(reference));
    navigate(reference, { replace: true });
  };
  return (
    <Box as="section" bg="brand.50">
      <Box
        py={["16", "16", "24", "24"]}
        maxW={["xl", "xl", "5xl", "5xl"]}
        mx="auto"
      >
        <Stack spacing={["12", "12", "16", "16"]} px={["10", "10", "0", "0"]}>
          <Stack spacing={["4", "4", "5", "5"]} maxW="3xl">
            <Stack spacing="3">
              <Text
                fontSize={["sm", "sm", "md", "md"]}
                fontWeight="semibold"
                color="brand.800"
              >
                Features
              </Text>
            </Stack>
            <Text color="muted" fontSize={["lg", "lg", "xl", "xl"]}>
              The features below have been bundled up as plans for Churches to
              subscribe to.
            </Text>
          </Stack>
          <SimpleGrid
            columns={["1", "1", "2", "3"]}
            columnGap={8}
            rowGap={[10, 10, 16, 16]}
          >
            {features.map((feature) => (
              <Stack key={feature.name} spacing={["4", "4", "5", "5"]}>
                <Square
                  size={["10", "10", "12", "12"]}
                  bg="brand.600"
                  color="brand.50"
                  borderRadius="lg"
                >
                  <Icon as={feature.icon} boxSize={["5", "5", "6", "6"]} />
                </Square>
                <Stack spacing={["1", "1", "2", "2"]}>
                  <Text
                    fontSize={["lg", "lg", "xl", "xl"]}
                    fontWeight="medium"
                    color="brand.800"
                  >
                    {feature.name}
                  </Text>
                  <Text color="muted">{feature.description}</Text>
                </Stack>
                <Button
                  variant="link"
                  color={"brand.700"}
                  rightIcon={<FiArrowRight fontSize="1.25rem" />}
                  alignSelf="start"
                  onClick={(e) => LearnMoreClick(e, feature.reff)}
                >
                  Learn More
                </Button>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Box>
    </Box>
  );
};
