import { Flex } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { Footer_ } from "../components/Footer_";
import Privacy_ from "../components/Privacy_";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex direction="column">
      <Privacy_ />
      <Footer_ />
    </Flex>
  );
}

export default Privacy;
