import {
  Box,
  Flex,
  Heading,
  Img,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { BsFillGridFill, BsPlusCircleFill } from "react-icons/bs";
import { FMoreFeat } from "./fMoreFeat";

export const FMore_ = (props) => {
  const { moreData } = props;
  return (
    <Box as="section">
      <Box
        maxW={["xl", "xl", "7xl", "7xl"]}
        mx="auto"
        px={["6", "6", "8", "8"]}
        py="16"
      >
        <Flex
          align="center"
          justify="center"
          direction={["column-reverse", "column-reverse", "row", "row"]}
        >
          <Box flex="1" maxW="440px" pt="5">
            <Heading
              as="h1"
              size="xl"
              fontWeight="extrabold"
              pb="4"
              color={useColorModeValue("brand.800", "blue.300")}
            >
              {moreData.textP0}
            </Heading>
            <Stack
              spacing="4"
              mx="4"
              my="4"
              px="4"
              py="4"
              bg="brand.100"
              borderRadius={"5"}
            >
              <FMoreFeat icon={BsFillGridFill} title={moreData.textP1Title}>
                {moreData.textP1}
              </FMoreFeat>
              <FMoreFeat icon={BsPlusCircleFill} title={moreData.textP2Title}>
                {moreData.textP2}
              </FMoreFeat>
            </Stack>
          </Box>

          <Box
            aria-hidden
            className="spacer"
            flexShrink={0}
            boxSize={{
              base: "10",
              xl: "20",
            }}
          />

          <Box
            flex="1"
            maxW="560px"
            h={{
              base: "400px",
              md: "460px",
            }}
          >
            <Img
              alt="Call center"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition="right"
              src={moreData.morepic}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
