import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { AppStore, GooglePlay } from "../util/logoPlayAppStore";

export const Action_ = () => {
  return (
    <Box
      as="section"
      bg={mode("brand.100", "gray.800")}
      py={["0", "0", "12", "12"]}
    >
      <Box shadow="sm" maxW={["xl", "xl", "5xl", "5xl"]} mx="auto">
        <Flex
          direction={["column", "column", "row", "row"]}
          align="center"
          justify="space-between"
          bg={[
            mode("", "gray.700"),
            mode("", "gray.700"),
            mode("brand.50", "gray.700"),
            mode("brand.50", "gray.700"),
          ]}
          pt="16"
          pb="12"
          ps={["8", "8", "14", "14"]}
          pe={["8", "8", "0", "0"]}
        >
          <Box flex="1" textAlign={["center", "center", "start", "start"]}>
            <Heading color={"brand.800"} fontWeight="extrabold">
              Get Parishioner App
            </Heading>
            <Text fontSize="lg" mt="2" color={"brand.800"}>
              Available on Google Play and App Store. Enable location during
              your first launch and select your Parish or Church
            </Text>
          </Box>
          <Box
            flex="1"
            display={["none", "none", "block", "block"]}
            mt={["6", "6", "0", "0"]}
          >
            <HStack justify="space-evenly">
              <Box as="a" href="#">
                <AppStore />
                <Box srOnly>Download from App Store</Box>
              </Box>
              <Box
                as="a"
                href="https://play.google.com/store/apps/details?id=com.parishionerapp.parishioner"
              >
                <GooglePlay />
                <Box srOnly>Download from Google Play</Box>
              </Box>
            </HStack>
          </Box>
          <Box
            flex="1"
            display={["block", "block", "none", "none"]}
            mt={["6", "6", "0", "0"]}
          >
            <Stack spacing="4">
              <Box as="a" href="#">
                <AppStore />
                <Box srOnly>Download from App Store</Box>
              </Box>
              <Box
                as="a"
                href="https://play.google.com/store/apps/details?id=com.parishionerapp.parishioner"
              >
                <GooglePlay />
                <Box srOnly>Download from Google Play</Box>
              </Box>
            </Stack>
          </Box>
        </Flex>
        <Flex
          direction={["column", "column", "row", "row"]}
          bg="brand.700"
          color="white"
        >
          <Box flex="1" pt="4" bg="whiteAlpha.200" mb="-px"></Box>
          <Center flex="1" py="8">
            <List fontSize={["xl", "xl", "2xl", "2xl"]} spacing="4">
              <ListItem>
                <ListIcon as={HiCheckCircle} />
                Continue with your parish
              </ListItem>
              <ListItem>
                <ListIcon as={HiCheckCircle} />
                All features in one place
              </ListItem>
              <ListItem>
                <ListIcon as={HiCheckCircle} />
                Stay connected with your church
              </ListItem>
            </List>
          </Center>
        </Flex>
      </Box>
    </Box>
  );
};
